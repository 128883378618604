import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { FormControlLabel, FormHelperText, Stack, Switch, Tooltip, } from '@mui/material';
// translation
import { useLocales } from '../../locales';
import Iconify from '../iconify';
export default function RHFSwitch({ name, label, helperText, labelPlacement = 'end', ...other }) {
    const { control } = useFormContext();
    const { translate } = useLocales();
    return (_jsx(Controller, { name: name, control: control, defaultValue: "false", render: ({ field, fieldState: { error } }) => (_jsxs(_Fragment, { children: [_jsxs(Stack, { sx: {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '10px',
                        width: '100%',
                    }, children: [_jsx(FormControlLabel, { sx: { ml: 0 }, label: `${translate(label)} `, onBlur: field.onBlur, labelPlacement: labelPlacement, control: _jsx(Switch, { ...field, checked: field?.value === undefined ||
                                    field?.value === '' ||
                                    typeof JSON.parse(field.value) !== 'boolean'
                                    ? false
                                    : JSON.parse(field?.value) }), ...other }), _jsx(Tooltip, { title: `${translate(helperText)}` || `${translate(label)}`, placement: "right-start", children: _jsx(Iconify, { icon: "ri:information-line", width: 23, sx: {
                                    cursor: 'help',
                                    color: '#9AA6B2',
                                    marginTop: labelPlacement === 'end' ? '23px' : '',
                                } }) })] }), !!error && (_jsx(FormHelperText, { error: !!error, children: error && `${translate(error?.message)}` }))] })) }));
}
